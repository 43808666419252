@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-Bold.eot');
  src: url('DMSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('DMSans-Bold.woff2') format('woff2'),
  url('DMSans-Bold.woff') format('woff'),
  url('DMSans-Bold.ttf') format('truetype'),
  url('DMSans-Bold.svg#DMSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-Italic.eot');
  src: url('DMSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('DMSans-Italic.woff2') format('woff2'),
  url('DMSans-Italic.woff') format('woff'),
  url('DMSans-Italic.ttf') format('truetype'),
  url('DMSans-Italic.svg#DMSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-Medium.eot');
  src: url('DMSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('DMSans-Medium.woff2') format('woff2'),
  url('DMSans-Medium.woff') format('woff'),
  url('DMSans-Medium.ttf') format('truetype'),
  url('DMSans-Medium.svg#DMSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-MediumItalic.eot');
  src: url('DMSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('DMSans-MediumItalic.woff2') format('woff2'),
  url('DMSans-MediumItalic.woff') format('woff'),
  url('DMSans-MediumItalic.ttf') format('truetype'),
  url('DMSans-MediumItalic.svg#DMSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-BoldItalic.eot');
  src: url('DMSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('DMSans-BoldItalic.woff2') format('woff2'),
  url('DMSans-BoldItalic.woff') format('woff'),
  url('DMSans-BoldItalic.ttf') format('truetype'),
  url('DMSans-BoldItalic.svg#DMSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-Regular.eot');
  src: url('DMSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('DMSans-Regular.woff2') format('woff2'),
  url('DMSans-Regular.woff') format('woff'),
  url('DMSans-Regular.ttf') format('truetype'),
  url('DMSans-Regular.svg#DMSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body {
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
}
