button.round {
  background: $u_white;
  border-radius: 20px;
  padding: 3px 10px;
  width: 100%;

  &.light-blue {
    border: solid 2px $u_blue;
    color: $u_blue;
  }

  &.blue {
    border: solid 2px $u_blue;
    color: $u_blue;
  }
}

button {
  box-shadow: none;
  border-radius: 20px;
  outline: none;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.simple-button {
  border: none;
  color: $u_white;
  background: $u_blue_dark;
  padding: 5px 20px;
  border-radius: 20px;
  text-decoration: none;
  display: inline-block;

  &:hover {
    color: $u_white;
  }
}

.submit-button {
  background-color: $u_blue;
  border-color: $u_blue;;
  border-radius: 50px;
  width: 120px !important;
  height: 40px !important;
}

.MenuIcon{
  display: none;
}

.info-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 1.3em;

  svg {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .MenuIcon{
    display: block;
  }
}