.perc-title {
  font-size: 2.3rem;
  font-weight: bold;
}

.pie-layout {
  &.red .perc-title {
    color: $u_red
  }

  &.green .perc-title {
    color: $u_green
  }

  &.light-blue .perc-title {
    color: $u_blue
  }

  &.blue .perc-title {
    color: $u_blue
  }

  &.orange .perc-title {
    color: $u_orange
  }
}
