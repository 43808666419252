.filters-nav {
  padding: 15px 0;
  position: relative;

  top: 0;
  left: 0;
  bottom: 0;
  width: $side_width;

  .filter-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

.filter-list {
  position: absolute;
  top: -10px;
  left: 5px;
  background: $u_yellow_light;
  color: $u_black;
  padding: 15px 30px 15px 60px;
  z-index: 1;
  width: auto;
  white-space: nowrap;
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  transition: .3s ease;

  .close-filters {
    position: absolute;
    left: 20px;
    top: 25px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 15px;
  }

  .filter {
    cursor: pointer;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .filter:hover {
    font-size: 18px;
    font-weight: bold;
  }
}