@import "var";

.bg-blue {
  background-color: $u_blue;
}

.bg-gray {
  background-color: $u_gray;
}

.bg-black {
  background-color: $u_black;
}

.bg-gray-light {
  background-color: $u_gray_light;
}

.color-gray-dark {
  color: $u_gray_dark
}

.color-blue {
  color: $u_blue
}

.color-blue-dark {
  color: $u_blue_dark
}

.color-blue-light {
  color: $u_blue
}

.color-green {
  color: $u_green
}

.color-red {
  color: $u_red;
}

.color-orange {
  color: $u_orange;
}

.color-white {
  color: $u_white !important;
}


.textarea {
  border: solid 2px $u_blue_dark;
  border-radius: 30px;
  padding: 5px 10px;
  width: 100%;
  background: transparent;
  display: block;
  color: $u_gray_dark;
  font-size: .95rem;

}

input[type=text], input[type=password], input[type=email], select {
  border: solid 2px $u_blue;
  border-radius: 30px;
  padding: 5px 10px;
  width: 100%;
  background: transparent;
  display: block;
  color: $u_blue;
  font-size: .95rem;
}

input[type=checkbox] {
  border: solid 1px $u_blue;
  border-radius: 30px;
  background: transparent;
  display: inline-block;
  color: $u_blue;
  font-size: .95rem;
} input[type=checkbox]:checked {
  background:  $u_blue;
}

input::placeholder {
  opacity: 1;
  color: $u_blue;
}


@media all and (min-width: 1200px) {
  .h-xl-100 {
    height: 100% !important;
  }
}


@media all and (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
}
