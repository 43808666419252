.login-error {
  color: $u_red;
  text-align: center;
}

.auth-form {
  padding: 50px 50px;
}

.auth-form .btn {
  height: 50px;
  font-weight: 700;
}

.auth-form .page-back {
  display: inline-block;
  margin-bottom: 15px;
}

.login {
  background: var(--rgba-primary-1);
  display: flex;
  min-height: 100vh;
}

.form-check-label {
  margin-left: 0.3125rem;
}

.login-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-side {
  background: rgb(255, 255, 255);
  height: 100vh
}

.form-side {
  color: $u_blue;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh
}

@media only screen and (max-width: 87.5rem) {
  .login .login-aside {
    max-width: 22.5rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .image-side {
    background: rgb(255, 255, 255);
    height: auto
  }

  .login .login-aside:after {
    content: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .image-side {
    background: rgb(255, 255, 255);
    height: auto
  }
  .auth-form {
    padding: 30px;
  }
  .form-side {
    max-height: 400px;
    height: auto
  }

  .login-content.style-2 .auth-form {
    padding: 30px 0px;
  }
}

.login-content {
  background: #fff;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 5px;
}

.login-content.style-2 {
  background: transparent;
  box-shadow: none;
  max-width: 580px;
  width: 100%;
}

.login-content.style-2 .form-control {
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}