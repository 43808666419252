.star {
  padding: 0 3px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.star-title {
  font-size: 1.3rem;
  font-weight: bold;
  padding-left: 20px;
}
